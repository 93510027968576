'use strict'

###*
 # @ngdoc object
 # @name mundoUtils.service:ColorService

 # @description

###
class ColorService
  seed_colors = {}

  colors  = [
  ]

  colorManager = {}

  ### @ngInject ###
  constructor:(
    ColorManager
  ) ->
    colorManager = ColorManager
    ## Gets default colors for tenant from backend
    ColorManager.one('tenant').then (results) ->
      #For some reason colors can't have their # so remove this first
      colors = results.colors.map (color) -> color.substring(1)

  # Used to call colors again by report config
  resetColorsWithAuth: (configid, shareid, accessToken) ->
    colorManager.getColorPalleteByReport(configid, shareid, accessToken).then (results) ->
      # For some reason colors can't have their # so remove this first
      colors = results.data.colors.map (color) -> color.substring(1)

  getColorAtIndexForNumber: (value) ->
    colors[value%colors.length]

  getColorBySeed: (seed) ->
    if not seed_colors[seed]?
      seed_colors[seed] = @getColorAtIndexForNumber Object.keys(seed_colors).length

    seed_colors[seed]

  resetSeedColors: () ->
    seed_colors = {}

angular
.module('mundoUtils')
.service('ColorService', ColorService)
